var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Staff"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',{staticClass:"is-narrow"},[_c('router-link',{attrs:{"to":{
        name: 'create-user'
      }}},[_vm._v("Create User")])],1)],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.users.data),function(user){return _c('div',{key:user.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',{staticClass:"is-flex"},[_c('avatar',{staticClass:"mr-2",attrs:{"path":user.profile.picture_url,"name":user.full_name,"size":24}}),_c('router-link',{attrs:{"to":{
                name: 'user-manager',
                params: {
                  user: user.uuid
                }
              }}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(user.full_name))]),(user.id === _vm.branch.branch_manager_id)?_c('span',{staticClass:"has-text-grey"},[_vm._v("Manager")]):_vm._e()])],1),_c('column',{staticClass:"is-3 has-text-right"},[_vm._v(" "+_vm._s(user.designation)+" ")])],1)],1)}),0),_c('pager',{attrs:{"pageable":_vm.users,"context":"User","jump-controls":""},on:{"nav":_vm.goToPage}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }