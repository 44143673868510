<template>
  <loader v-bind="{ loading }" text="Loading Staff">
    <columns>
      <column class="is-narrow">
        <router-link :to="{
          name: 'create-user'
        }">Create User</router-link>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="user in users.data" :key="user.id">
            <columns>
              <column class="is-flex">
                <avatar 
                  class="mr-2"
                  :path="user.profile.picture_url"
                  :name="user.full_name"
                  :size="24"
                />
                <router-link :to="{
                  name: 'user-manager',
                  params: {
                    user: user.uuid
                  }
                }">
                    <span class="mr-2">{{ user.full_name }}</span>
                    <span class="has-text-grey" v-if="user.id === branch.branch_manager_id">Manager</span>
                </router-link>
              </column>
              <column class="is-3 has-text-right">
                {{ user.designation }}
              </column>
            </columns>
          </div>
        </div>
        <pager :pageable="users" context="User" jump-controls @nav="goToPage" />
      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    loading: true,
    users: {
      data: []
    }
  }),

  created() {
    this.loadUsers()
  },

  methods: {
    loadUsers() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.users = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
  },

  computed: mapGetters('branch', [
    'branch'
  ]),

  watch: {
    '$route': 'loadUsers'
  }

}
</script>